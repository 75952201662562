

















































































































































import BindChannel from "@/components/Modules/components/facecontrol/BindChannel.vue";
import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'
import { PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions";
import PunishActionSetting from "@/components/Modules/punish-actions/components/PunishActionSetting.vue";
import MessageBindedChannelHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/MessageBindedChannelHandlerModule/MessageBindedChannelHandlerModuleBuilder";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import { CHATKEEPER_BOT_LOGIN } from "@/includes/constants";
import BindChannelInfo from "../../components/facecontrol/BindChannelInfo.vue";

import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue';
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue';
import { FieldData } from "piramis-base-components/src/components/Pi/types";
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue';
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      CHATKEEPER_BOT_LOGIN,
      StepUnit
    }
  },
  components: {
    NotAvailableOptionsOverlay,
    BindChannel,
    BindChannelInfo,
    PunishActionSetting,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SwitchInput,
    TimeUnitInput,
    NestedContent,
    NumberInput,
  }
})
export default class MessageBindedChannelHandlerModuleView extends Mixins(ModuleBindings, InputSetups, TariffsTagsHelper) {
  @VModel() module!: MessageBindedChannelHandlerModuleBuilder

  punishActionTypes: Array<PunishActionTypesEnum> = [
    PunishActionTypesEnum.Notify,
    PunishActionTypesEnum.DeleteMessage,
    PunishActionTypesEnum.MuteMedia,
    PunishActionTypesEnum.Mute,
    PunishActionTypesEnum.Ban,
    PunishActionTypesEnum.Kick,
  ]

  isBindModalOpen = false

  get tariffTag() {
    return this.module.tariffTags()
  }

  setRecheckTime(arg: FieldData): FieldData {
    arg.setter = (val) => {
      if (isNaN(val)) {
        arg.model[arg.key] = 600
      } else {
        if (val >=600 || val === 0) {
          arg.model[arg.key] = val
        } else {
          arg.model[arg.key] = 600
        }
      }
    }

    return arg
  }

  channelBindWarning(value: number | string): void {
    this.$confirm({
      title: this.$t('pi_warning').toString(),
      content: this.$t('module_binded_channel_confirm_text').toString(),
      okText: this.$t('do_save_chat_config').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.module.model.params.binded_channel_id = +value

        this.saveModule()
      }
    })
  }

  unbind() {
    this.module.model.params.binded_channel_id = 0
    // this.module.model.config.enabled = false
    // this.saveModule()
  }

  bindFaceControlChannel(channelId: number | undefined): void {
    if (channelId) {
      this.channelBindWarning(channelId)
    }
  }

  mounted() {
    if (this.tariffTag) {
      this.$baseTemplate.saveButton.hide()
    } else {
      this.$baseTemplate.saveButton.show()
    }
  }
}
