




















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class BindChannelInfo extends Vue {
  @Prop() bindedChannelId!: number

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit()
  onUnbind() {
    return null
  }
}
